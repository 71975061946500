import { useTranslation } from 'i18n/TranslationContext'
import {
  Box, Center,
  Input, InputGroup, InputRightElement, Popover, PopoverAnchor, PopoverContent, Spinner
} from '@chakra-ui/react'
import { forwardRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchLocationQuery } from 'features/locationsApi'
import Downshift from 'downshift'
import FormErrorDisplay from '../FormErrorDisplay'

const MenuItems = ({
  items,
  getItemProps,
  highlightedIndex,
  selectedItem
}) => {
  const { t } = useTranslation()
  if (!items) { return null }

  if (items.length === 0) {
    return (
      <Center>
        {t('No results')}
      </Center>
    )
  }

  return (
    items
      .map((item, index) => (
        <Box as="li"
          key={item.place_id}
          {...getItemProps({
            key: item.place_id,
            index,
            item
          })}
          backgroundColor={highlightedIndex === index ? 'lightgray' : 'white'}
          fontWeight={selectedItem === item ? 'bold' : 'normal'}
          fontSize="18px"
          color="#0F204A"
          padding="8px 24px"
        >
          {item.description}
        </Box>
      ))
  )
}

const Label = (props) => (
  <Box
    as="label"
    display="block"
    fontWeight="500"
    fontSize={['20px', null, '24px']}
    marginBottom="16px"
    {...props}
  />
)

const StyledInput = forwardRef((props, ref) => (
  <Input
    ref={ref}
    errorBorderColor="#CD0050"
    _hover={{ borderColor: '#0F204B' }}
    _focusVisible={{ borderColor: '#3182ce', boxShadow: '0 0 0 1px #3182ce' }}
    textTransform="none"
    fontWeight="normal"
    fontSize={['14px', null, '18px']}
    borderRadius="4px"
    border="2px solid #0F204B"
    height={['54px', null, '74px']}
    {...props}
  />
))
StyledInput.displayName = 'StyledInput'

const ComboboxContent = ({
  getLabelProps,
  getMenuProps,
  getRootProps,
  getInputProps,
  isOpen,
  inputValue,
  getItemProps,
  selectedItem,
  highlightedIndex,
  CustomLabel,
  CustomHelperText,
  requiredMessage,
  error
}) => {
  const { t } = useTranslation()
  const [popoverOpenState, setPopoverOpenState] = useState(false)

  const { register, formState: { errors } } = useFormContext()
  const registered = register('location',
    {
      required: requiredMessage ? requiredMessage : false
    }
  )

  const isSearchActive = inputValue && inputValue.length >= 2
  const { data: items, isFetching } = useSearchLocationQuery(inputValue, {
    skip: !isSearchActive
  })

  useEffect(() => {
    setPopoverOpenState(isOpen && isSearchActive && !isFetching)
  }, [isSearchActive, isOpen, isFetching])

  return (
    <Popover
      autoFocus={false}
      isOpen={popoverOpenState}
      matchWidth
    >
      {CustomLabel ?
        <CustomLabel {...getLabelProps()} />
        :
        <Label {...getLabelProps()}>
          {t('Location')}
        </Label>
      }
      {CustomHelperText && <CustomHelperText />}
      <PopoverAnchor>
        <Box>
          <div >
            <InputGroup>
              <StyledInput
                {...getRootProps({}, { suppressRefError: true })}
                {...getInputProps({
                  onBlur: registered.onBlur
                })}
                placeholder="New York, NY"
                isInvalid={!!error}
              />
              <InputRightElement height="100%">
                {isFetching ? (
                  <Spinner color="blue.400" size="sm" />
                ) : null}
              </InputRightElement>
            </InputGroup>
          </div>
          {error && <FormErrorDisplay name="location" errorVal={errors} />}
        </Box>
      </PopoverAnchor>
      <PopoverContent width="100%">
        <Box as="ul"
          {...getMenuProps()}
          padding="16px 0"
          fontSize="18px"
          borderRadius="4px"
          boxShadow="0px 4px 11px 4px rgba(0, 0, 0, 0.11)"
        >
          <MenuItems
            items={items}
            getItemProps={getItemProps}
            selectedItem={selectedItem}
            highlightedIndex={highlightedIndex}
          />
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export const LocationInput = ({ CustomLabel, CustomHelperText, requiredMessage }) => {
  const {
    setValue,
    watch,
    formState: { errors },
    trigger
  } = useFormContext()
  const location = watch('location')

  return (
    <Box pb="24px" >
      <Downshift
        defaultHighlightedIndex={0}
        selectedItem={location}
        onChange={
          (value) => {
            if (!value) {
              setValue('location', null)
            } else {
              setValue('location', {
                place_id: value.place_id,
                formatted_address: value.description
              })
            }
            trigger('location') // Trigger validation after change
          }
        }
        itemToString={(item) => (item ? (item.formatted_address || '') : '')}
        onInputValueChange={(inputValue) => {
          if (!inputValue) {
            setValue('location', null)
            trigger('location') // Trigger validation when input is cleared
          }
        }}
      >
        {({
          getRootProps,
          getLabelProps,
          getInputProps,
          getMenuProps,
          isOpen,
          inputValue,
          getItemProps,
          selectedItem,
          highlightedIndex
        }) => (
          <div
            ref={getRootProps({}, { suppressRefError: true }).ref}
          >
            <ComboboxContent
              getRootProps={getRootProps}
              getLabelProps={getLabelProps}
              getInputProps={getInputProps}
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              isOpen={isOpen}
              inputValue={inputValue}
              selectedItem={selectedItem}
              highlightedIndex={highlightedIndex}
              CustomLabel={CustomLabel}
              CustomHelperText={CustomHelperText}
              requiredMessage={requiredMessage}
              error={errors.location}
            />
          </div>
        )}
      </Downshift>
    </Box>
  )
}
