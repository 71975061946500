import { apiSlice } from './apiSlice'

export const partnersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchGreenhouseJobs: builder.query({
      query: (partnerId) => `partners/${partnerId}/greenhouse/jobs`,
    }),
    getGreenhouseUsers: builder.mutation({
      query: ({ partnerProfileId, greenhouseApiKey }) => ({
        url: `partners/${partnerProfileId}/greenhouse/users`,
        method: 'GET',
        params: { greenhouse_api_key: greenhouseApiKey }
      }),
    }),
  })
})

export const {
  useFetchGreenhouseJobsQuery,
  useGetGreenhouseUsersMutation,
} = partnersApi
