import React, { useState } from 'react'
import { Box, FormControl, Input, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { formStyles } from '../formStyles'

const TagInputField = ({ helperText, label, name, inputProps, fieldRequired, tagsProp, errorVal={} }) => {
  // eslint-disable-next-line no-unused-vars
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const { sharedStyles, inputFieldStyles, tagStyle } = formStyles()

  const onInputChange = (e) => {
    const { value } = e.target
    setInputValue(value)
  }

  const onKeyDown = (e) => {
    const { key } = e
    const trimmedInput = inputValue.trim()
    const keyOptions = ['Enter', ',', 'ArrowDown', 'Tab']
    if (keyOptions.includes(key) && trimmedInput.length && !tagsProp.tags.includes(trimmedInput) && tagsProp.tags.length <= tagsProp.maxTags) {
      e.preventDefault()
      tagsProp.setTags(prevState => [...prevState, trimmedInput])
      setInputValue('')
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const deleteSkills = (index) => {
    tagsProp.setTags(prevState => prevState.filter((skill, i) => i !== index))
  }

  return (
    <FormControl pb="40px" isInvalid={!!errorVal[name]} isRequired={fieldRequired}>
      <FieldHeader helperText={helperText} label={label} />
      <Box>
        {tagsProp.tags.map((skill, index) => (
          <Tag
            className="skill"
            size="lg"
            key={skill}
            borderRadius="full"
            variant="solid"
            style={{ ...tagStyle }}
          >
            <TagLabel>{skill}</TagLabel>
            {
              !inputProps.disabled && (
                <TagCloseButton onClick={() => deleteSkills(index)} />
              )
            }
          </Tag>
        ))}
      </Box>
      {
        !inputProps.disabled && (
          <Input
            value={inputValue}
            isInvalid={!!errorVal[name]}
            {...inputProps}
            {...sharedStyles}
            {...inputFieldStyles}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        )
      }
      <FormErrorDisplay courseBuilder={true} name={name} errorVal={errorVal} />
    </FormControl>
  )
}

export default TagInputField
