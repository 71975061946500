import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useCreateOpportunityApplicationMutation } from 'features/opportunitiesApi'
import { buttonStyles } from './buttonStyles'

const SaveOpportunityButton = ({ opportunityId, talentUserId }) => {
  const [createOpportunityApplication] = useCreateOpportunityApplicationMutation()
  const { t } = useTranslation()
  const { primaryButtonStyle } = buttonStyles()

  const handleAddOpportunity = async () => {
    try {
      await createOpportunityApplication({ talentUserId, opportunityId, status: 'created' }).unwrap()
    } catch (error) {
      console.error('Failed to add opportunity:', error)
    }
  }

  return (
    <Button onClick={handleAddOpportunity} {...primaryButtonStyle}>
      {t('Save')}
    </Button>
  )
}

export default SaveOpportunityButton
