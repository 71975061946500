import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ModalBase, PrimaryButton } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useUpsertOpportunityMutation } from 'features/opportunitiesApi'

const PartnerOpportunityModal = ({ isOpen, onClose, action, opportunity, onConfirm }) => {
  const { t } = useTranslation()
  const [upsertOpportunityStatus] = useUpsertOpportunityMutation()

  const handleConfirm = async (newStatus) => {
    const formDataObject = { status: newStatus }

    try {
      await upsertOpportunityStatus({
        opportunityData: formDataObject,
        id: opportunity.id
      }).unwrap()
      onClose()
      onConfirm(newStatus)
    } catch (error) {
      console.error('Failed to update opportunity status', error)
    }
  }

  const modalConfig = {
    inactive: {
      header: t('Mark Opportunity as Inactive'),
      body: t('You are about to move this opportunity to an inactive state, either Paused or Closed.'),
      subBodyText: t('Would you like to proceed?'),
      primaryCTA: t('Yes, Pause'),
      primaryAction: 'paused',
      tertiaryCTA: t('Yes, Close'),
      tertiaryAction: 'closed',
    },
    active: {
      header: t('Mark Opportunity as Open'),
      body: t('You are about to move this opportunity to an Open state.'),
      subBodyText: t('Would you like to proceed?'),
      primaryCTA: t('Yes, Open'),
      primaryAction: 'open',
    },
    close: {
      header: t('Mark Opportunity as Closed'),
      body: t('You are about to move this opportunity to a Closed state.'),
      subBodyText: t('Would you like to proceed?'),
      primaryCTA: t('Yes, Close'),
      primaryAction: 'closed',
    },
  }

  const config = modalConfig[action]

  const footerButtons = (
    <Flex width="100%" justifyContent="space-between">
      <Flex>
        {config.tertiaryCTA && (
          <PrimaryButton
            onClick={() => handleConfirm(config.tertiaryAction)}
            textValue={config.tertiaryCTA}
            size="lg"
            fontSize="18px"
            variant="outline"
            colorScheme="zettaBlue"
            borderColor="#5000E8"
            color="#5000E8"
            mr={3}
          />
        )}
      </Flex>
      <Flex>
        <PrimaryButton
          onClick={onClose}
          variant="solid"
          bgColor="white"
          fontSize="18px"
          color="purple.600"
          fontWeight="normal"
          _hover={{
            bgColor: 'rgba(128, 90, 213, 0.1)',
          }}
          textValue={t('No, Cancel')}
          size="lg"
          mr={3}
        />
        <PrimaryButton
          onClick={() => handleConfirm(config.primaryAction)}
          textValue={config.primaryCTA}
          fontSize="18px"
          fontWeight="600"
          fontFamily="Spline Sans Mono"
          colorScheme="zettaBlue"
          size="lg"
        />
      </Flex>
    </Flex>
  )

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      headerText={config.header}
      bodyText={config.body}
      subBodyText={config.subBodyText}
      footerButtons={footerButtons}
      showClose={true}
    />
  )
}

export default PartnerOpportunityModal
