import React from 'react'
import { CourseBuilderProvider, useCourseBuilderContext } from './CourseBuilderContext'
import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { GridItem } from '@chakra-ui/react'
import { CourseStepsAccordion } from 'components'
import { FormRender } from './Forms/FormRender'
import { useNavigate } from 'react-router-dom'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import useNavBlocker from 'hooks/useNavBlocker'
import { IGNORE_NAV_BLOCKER } from 'constants'
import BuilderLayout from 'components/builder/BuilderLayout'

const ConfirmExitBlockerModal = () => {
  const { t } = useTranslation()
  const { currentFormDirty } = useCourseBuilderContext()
  const { ConfirmExitModalComponent } = useNavBlocker(
    t('You may have unsaved changes. Would you still like to proceed without saving?'),
    currentFormDirty
  )

  return <ConfirmExitModalComponent />
}

export const CourseBuilder = () => {
  const { t } = useTranslation()
  usePageTitle(t('Build a Talent pipeline'))
  const { introTextVals } = useGetFormTextValues()
  const navigate = useNavigate()

  return (
    <CourseBuilderProvider>
      <CourseBuilderContent
        t={t}
        introTextVals={introTextVals}
        navigate={navigate}
      />
    </CourseBuilderProvider>
  )
}

const CourseBuilderContent = ({ t, introTextVals, navigate }) => {
  const { saveBuilderData } = useCourseBuilderContext()

  const handleSaveExit = async () => {
    const continueProcess = await saveBuilderData()

    if (continueProcess) {
      navigate('/courses', IGNORE_NAV_BLOCKER)
    }
  }

  return (
    <BuilderLayout
      pageTitle={t('Build a Talent pipeline')}
      description={introTextVals.course_builder}
      backLinkText={t('Back to Courses')}
      backLinkHref="/courses"
      onSave={handleSaveExit}
      saveButtonText={t('Save as Draft')}
    >
      <ConfirmExitBlockerModal />
      <CourseStepsAccordion />
      <GridItem colSpan={4}>
        <FormRender />
      </GridItem>
    </BuilderLayout>
  )
}
