import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Grid,
  GridItem,
  Link as ChakraLink,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { WelcomeBar } from 'WelcomeBar'
import { ListHeader, ListBlock } from 'components'
import { PageOuterContainer } from 'shared/TalentsLayout'
import { formStyles } from 'components/forms/formStyles'

const fontSize = ['18px', '18px', '20px']

const BuilderLayout = ({
  pageTitle,
  description,
  backLinkText,
  backLinkHref,
  onSave,
  saveButtonText,
  children
}) => {
  const { t } = useTranslation()
  const { formPrimaryButtonStyle } = formStyles()

  return (
    <PageOuterContainer greenBg>
      <WelcomeBar />
      <ListBlock>
        <Grid>
          <GridItem colSpan={6}>
            <Box>
              <Button
                fontSize={fontSize}
                as={ChakraLink}
                fontWeight="400"
                fontFamily="Spline Sans Mono"
                textDecoration="none"
                variant="link"
                href={backLinkHref}
                colorScheme="zettaBlue"
              >
                <ArrowBackIcon marginRight="1.5" />
                {backLinkText}
              </Button>
            </Box>
            <Box
              display="block"
              padding="24px 0"
              style={{ clear: 'both' }}
            >
            </Box>
          </GridItem>
          <GridItem colSpan={4}>
            <ListHeader as="h1">
              {pageTitle}
            </ListHeader>
            <Box fontFamily="Poppins" fontSize={fontSize} p={4} float="left" padding="0px 0px 20px">
              {description}
            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <Button
              fontSize={fontSize}
              as={ChakraLink}
              fontWeight="400"
              fontFamily="Spline Sans Mono"
              textDecoration="none"
              padding="15px 30px"
              variant="link"
              href={backLinkHref}
              colorScheme="zettaBlue"
            >
              {t('Cancel')}
            </Button>
            <Button
              as={ChakraLink}
              float="right"
              margin="4px 10px 0px 0px"
              {...formPrimaryButtonStyle}
              padding="25px"
              onClick={onSave}
            >
              {saveButtonText || t('Save')}
            </Button>
          </GridItem>
          {children}
        </Grid>
      </ListBlock>
    </PageOuterContainer>
  )
}

export default BuilderLayout