import React, { useState, useEffect } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, FormControl, Grid, GridItem, Button, Flex, Icon } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { PhotoInput } from '../PhotoInput'
import { StyledFormLabel, InputField, SelectField } from 'components'
import { ProfileInputFields } from '../ProfileInputFields'
import { StyledTextArea } from '../StyledTextArea'
import { UploadResumeInput } from '../UploadResumeInput'
import { SkillsPane } from 'TalentProfile/Edit/SkillsPane'
import { FEATURE_FLAGS } from 'constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useGetGreenhouseUsersMutation } from 'features/partnersApi'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'

const Tape = () => (
  <Box
    position="absolute"
    width="100%"
    top={['-6px', null, '-42px']}
    left={['50%', null, 'unset']}
    zIndex="100"
  >
    <Box as="img"
      alt=""
      margin={[null, null, '0 auto']}
      maxWidth={['190px', null, 'unset']}
      src={`${process.env.PUBLIC_URL}/profile-photo-tape.svg`}
    />
  </Box>
)

const TestGreenhouseApiButton = ({ onTest, isLoading, isSuccess, error }) => {
  const { t } = useTranslation()
  return (
    <Flex alignItems="center">
      <Button
        onClick={onTest}
        colorScheme="zettaWhite"
        fontWeight="500"
        isLoading={isLoading}
        mr={2}
      >
        {t('Test Greenhouse API Key')}
      </Button>
      {isSuccess && !isLoading && (
        <Icon
          as={HiCheckCircle}
          data-testid="greenhouse-api-success-icon"
          boxSize="28px"
          color="green.500"
          ml={2}
        />
      )}
      {error && (
        <Icon
          as={HiXCircle}
          data-testid="greenhouse-api-fail-icon"
          boxSize="28px"
          color="red.500"
          mr={2}
        />
      )}
    </Flex>
  )
}

const PartnerFormContent = () => {
  const { t } = useTranslation()
  const { register, watch, setValue, control } = useFormContext()
  const partnerProfileOwner = watch('partnerProfileOwner')
  const partnerProfileId = watch('partnerProfileId')
  const greenhouseApiKey = watch('greenhouseApiKey')
  const greenhouseApiValid = watch('greenhouseApiValid')
  const greenhouseApiUserId = watch('greenhouseApiUserId')
  const { isEnabled: greenhouseEnabled } = useFeatureFlags(FEATURE_FLAGS.GREENHOUSE_INTEGRATION)
  const [apiTestError, setApiTestError] = useState(null)
  const [isTestSuccessful, setIsTestSuccessful] = useState(false)
  const [greenhouseUsers, setGreenhouseUsers] = useState([])

  const [getGreenhouseUsers, { isLoading: isTestingApi }] = useGetGreenhouseUsersMutation()

  const testGreenhouseApi = async () => {
    setIsTestSuccessful(false)
    setApiTestError(null)
    setValue('greenhouseApiValid', false)

    try {
      const result = await getGreenhouseUsers({ partnerProfileId, greenhouseApiKey }).unwrap()
      if (result) {
        setGreenhouseUsers(result)
        setIsTestSuccessful(true)
        setValue('greenhouseApiValid', true)
      }
    } catch (error) {
      setApiTestError('Failed to validate API key')
      setValue('greenhouseApiValid', false)
    }
  }

  useEffect(() => {
    const fetchGreenhouseUsers = async () => {
      try {
        const result = await getGreenhouseUsers({ partnerProfileId, greenhouseApiKey }).unwrap()
        if (result) {
          setGreenhouseUsers(result)
          setValue('greenhouseApiValid', true)
        }
      } catch (error) {
        console.error('Failed to fetch Greenhouse users:', error)
        setValue('greenhouseApiValid', false)
      }
    }

    if (greenhouseApiValid || greenhouseApiUserId) {
      fetchGreenhouseUsers()
    }
  }, [greenhouseApiValid, greenhouseApiUserId, partnerProfileId, greenhouseApiKey, getGreenhouseUsers, setValue])

  return (
    <Grid
      gap={30}
      templateAreas={[
        `"photo"
         "main"
         "resume"`,
        null,
        `"main photo"
         "resume photo"`
      ]}
      gridTemplateColumns={[null, null, '1.6fr 1fr']}
    >
      <GridItem area="photo">
        <Center>
          <Box
            position="relative"
            mt={['-12px', null, '-94px']}
            mb={8}
            width={[null, null, '100%']}
          >
            <Tape />
            <PhotoInput />
          </Box>
        </Center>
      </GridItem>

      <GridItem area="main">
        <InputField
          label="Partner name"
          name="name"
          inputProps={{
            placeholder: t('Charlotte Smith'),
            ...register(
              'name', {
                required: t('Name can\'t be empty')
              }
            )
          }}
        />
        {partnerProfileOwner && greenhouseEnabled && (
          <>
            <InputField
              label={t('Greenhouse API Key')}
              name="greenhouseApiKey"
              paddingBottom="20px"
              inputProps={{
                placeholder: t('Place API key here'),
                ...register('greenhouseApiKey')
              }}
            />
            <Box mt={1} mb={4}>
              <TestGreenhouseApiButton
                onTest={testGreenhouseApi}
                isLoading={isTestingApi}
                isSuccess={isTestSuccessful}
                error={apiTestError}
              />
            </Box>
            <SelectField
              label={t('Greenhouse API User')}
              name="greenhouseApiUserId"
              helperText={t('Select a user from your Greenhouse instance to serve as the proxy that sends data to and from Making Space. Note: This user must have Site Admin or Job Admin permissions.')}
              control={control}
              options={greenhouseUsers.map(user => ({ value: user.id, label: user.name }))}
              isDisabled={!greenhouseApiValid && !greenhouseApiUserId}
              placeholder={t('Select Greenhouse User')}
              width="100%"
            />
            <input type="hidden" {...register('greenhouseApiValid')} />
          </>
        )}
      </GridItem>
    </Grid>
  )
}

const TalentFormContent = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()
  const { isEnabled: compassEnabled } = useFeatureFlags(FEATURE_FLAGS.COMPASS)

  return (
    <Grid
      gap={30}
      templateAreas={[
        `"photo-skills"
         "main"
         "resume"`,
        null,
        `"main photo-skills"
         "resume photo-skills"`
      ]}
      gridTemplateColumns={[null, null, '1.6fr 1fr']}
    >
      <GridItem area="photo-skills">
        <Center>
          <Box
            position="relative"
            mt={['-12px', null, '-94px']}
            mb={8}
            width={[null, null, '100%']}
          >
            <Tape />
            <PhotoInput />
          </Box>
        </Center>
        <FormControl mb={8}>
          <StyledFormLabel>
            {t('Alt text for photo')}
          </StyledFormLabel>
          <StyledTextArea
            placeholder={t('Alternative text for your photo')}
            {...register('altText')}
          />
        </FormControl>

        {compassEnabled && <SkillsPane />}
      </GridItem>

      <GridItem area="main">
        <ProfileInputFields />
      </GridItem>

      <GridItem area="resume">
        <UploadResumeInput />
      </GridItem>
    </Grid>
  )
}

export const FormContent = () => {
  const locationPath = window.location.pathname
  if (locationPath.includes('partner')) {
    return <PartnerFormContent />
  } else {
    return <TalentFormContent />
  }
}
