// import { useGetCurrentUserQuery } from 'features/sessionsApi'
// import { useDownloadFile } from 'hooks/useDownloadFile'
// import { useSelector } from 'react-redux'
import { useListTalentProfilesQuery } from 'features/talentProfilesApi'
import { useMatch, useSearchParams, useParams } from 'react-router-dom'

export const unwrapSearchParams = (searchParams) => (
  new URL(searchParams.get('backTo'), window.location.href).searchParams
)

export const useGetSearchParams = () => {
  let [searchParams] = useSearchParams()
  const isMain = !!useMatch('/opportunities/applications')
  // const isContactRequest = !!useMatch('/talent/list/view_profile/:talentProfileId/contact_request')

  if (isMain) { return searchParams }

  searchParams = unwrapSearchParams(searchParams)

  // if (!isContactRequest) { return searchParams }

  return unwrapSearchParams(searchParams)
}

// Downloads a CSV file of the talent profiles for the current course
// export const useTalentProfileCsvExport = () => {
//   const searchParams = useGetSearchParams()
//   const courseId = searchParams.get('course_id')
//
//   const dtRegExp = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/
//   const timestamp = new Date().toISOString().replace(/[-:.T]/g, '').replace(dtRegExp, 'talent-profiles-$1-$2-$3-$4$5Z')
//   const filename = `${timestamp}.csv`
//   const { isLoading, triggerDownload } = useDownloadFile(`/api/talent_profiles/csv?course_id=${courseId}`, filename)
//
//   return {
//     handleExportCsv: () => triggerDownload(),
//     isLoading
//   }
// }

const useGetTalentProfileListArgs = () => {
  const { opportunityId } = useParams()
  const searchParams = useGetSearchParams()
  const pageNumber = searchParams.get('page') || '1'
  // const courseId = searchParams.get('course_id')

  // const { lastSearchParams } = useSelector((state) => state.talentSearch)
  // const {
  //   data: { partner_profile: { id } }
  // } = useGetCurrentUserQuery()

  // return {
  //   pageNumber,
  //   courseOwnerId: id,
  //   courseId,
  //   searchDrawerParams: lastSearchParams
  // }
  //
  return {
    pageNumber,
    opportunityId,
  }
}

export const useApplicationListData = () => {
  const args = useGetTalentProfileListArgs()
  return useListTalentProfilesQuery(args)
}
