import { useMemo } from 'react'
import { useTranslation } from 'i18n/TranslationContext'

export const useGetFormTextValues = () => {
  const { t } = useTranslation()

  const helperTextVals = useMemo(() => ({
    course_name: t('This name appears in the list of courses available for talent and throughout the course material.'),
    course_additional_materials: t('Add links to materials used throughout the course episodes like slideshows or softwares. These will appear under the Additional Materials of each episode. Up to three (3) supplemental materials can be added.'),
    course_summary: t('This shortened course description appears in the list of courses available for talent.'),
    course_description: t('This longer course description appears on the course description page and should give talent a more in-depth view of what they can expect to learn in the course.'),
    course_skills: t('Identify the skill(s) that talent can expect to gain throughout this course. Add between one (1) and three (3) skills.'),
    course_image: t('This picture appears in the list of courses available for talent. It will also be used as the primary photo on the course description page. File size is limited to 5MB. PNG or JPG only.'),
    course_expert_name: t('Let talent know who will be guiding them through this course and their learning journey. One (1) expert is required, and you may add up to a total of three (3) experts.'),
    course_expert_image: t('File size is limited to 5MB. PNG or JPG only. Recommended ratio for image is 3:4.'),
    course_visibility: t('Courses can be Public or Private. A public course will be available for all talent users on Making Space to enroll. A private course is only accessible to users you invite.'),
    episode_name: t('This title is used on the corresponding episode page and in the course outline on the course description page.'),
    episode_blurb: t('This short description is used on the corresponding episode page and in the course outline on the course description page.'),
    sign_language_video: t('Video length is limited to 10 minutes with a maximum file size of 250 MB. MP4 only.'),
    episode_quiz_question: t('Add a question for talent users to answer that corresponds to the video content from this episode.'),
    episode_quiz_answer: t('Add the correct answer to the question above. All answers will be randomized for talent.'),
    episode_quiz_incorrect_answer: t('Add three (3) incorrect answers to the question above. All answers will be randomized for talent.'),
    assignment_description: t('Enter the details for this assignment including the purpose, what talent is expected to submit, and any additional information that may be helpful.'),
    greenhouse_api_job_id: t('Add the Job ID for an associated Job from Greenhouse to sync Talent user data directly to your ATS. The Job ID is the last numerical string on the URL of your Job. Ex. https://app2.greenhouse.io/sdash/{JOB_ID}.')
  }), [t])

  const introTextVals = useMemo(() => ({
    course_builder: t('Transform each stage of your talent pipeline into a comprehensive course, equipping talent with the essential knowledge needed to skill up.'),
    episode_accessibility_files: t('Each video hosted on Making Space must be accessible for our various users. We do that by requiring that each piece of content includes a transcript, an audio description file, and a sign language video.'),
    episode_quiz_1: t('Quizzes are an easy way to perform knowledge checks throughout the course material. You may add one (1) quiz per episode and up to three (3) questions per quiz, all multiple choice. (Optional)'),
    episode_quiz_2: t('Each quiz should correspond to content from the episode just watched.'),
    course_assignment: t('Ask talent to demonstrate the skills learned in your course by adding an assignment. You may add one (1) assignment to each course.')
  }), [t])

  const placeholderTextVals = useMemo(() => ({
    course_name: t('Introduction to Meteorology'),
    course_summary: t('Learn the ABCs of meteorology with a specific focus on the study of clouds.'),
    course_description: t('Delve into the fundamentals of weather science! Covering topics such as atmospheric composition, temperature, pressure systems, and precipitation patterns, you will gain a comprehensive understanding of meteorological principles. Throughout this course, explore the dynamics of weather phenomena, including cloud formation, wind patterns, and severe weather events. By the end of the course, you will emerge with a solid foundation in meteorology, equipped to interpret weather forecasts and understand the factors influencing Earth\'s atmospheric conditions.'),
    course_skills: t('Add Skill...'),
    course_expert_name: t('Barbara Gordon'),
    course_expert_title: t('Vigilante'),
    course_expert_bio: t('Barbara Gordon is best known as Gotham\'s Batgirl. Later in her career, she became known as Oracle and assisted Batman in locating Gotham\'s most notorious villains including but not limited to: Joker, The Riddler, and Harvey "Two-Face" Dent.'),
    course_expert_link: 'http://batgirl.com',
    course_expert_linkedin: 'linkedin.com/batgirl',
    course_expert_instagram: 'instagram.com/batgirl',
    course_expert_tiktok: 'tiktok.com/batgirl',
    episode_quiz_question: (t('What type of cloud is typically associated with thunderstorms and can extend vertically into the atmosphere?')),
    assignment_description: t('Observe the sky in a city of your choosing and identify the different cloud types based on their characteristics. Document those findings over the course of 5 days.'),
    greenhouse_api_job_id: t('123456789'),
  }), [t])

  return { helperTextVals, placeholderTextVals, introTextVals }
}
