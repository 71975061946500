import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useFetchGreenhouseJobsQuery } from 'features/partnersApi'
import { EditorState } from 'draft-js'
import { useBuildMultiForm } from 'hooks/courseBuilder/useBuildMultiForm'
import { sendCourseData } from 'hooks/courseBuilder/useSendCourseData'
import { getCourseStepsContent } from './getCourseStepsContent'
import { BuilderProvider, useBuilderContext } from 'components/builder/BuilderContext'
import validateAndContinue from 'utils/courseBuilder/validateAndContinue'
import { useAddNewCourseMutation } from 'features/coursesApi'

export const CourseBuilderProvider = ({ children }) => {
  const [createNewCourse] = useAddNewCourseMutation()
  const [expertCount, setExpertCount] = useState([0])

  const initialQuizState = {}
  for (let i = 1; i <= 50; i++) {
    initialQuizState[i] = [0]
  }

  const [quizCount, setQuizCount] = useState(initialQuizState)
  const [courseMaterialCount, setCourseMaterialCount] = useState([0])
  const [assignmentMaterialCount, setAssignmentMaterialCount] = useState([0])
  const [courseNameList, setCourseNameList] = useState([])
  const [episodeCount, setEpisodeCount] = useState(1)
  const [visibilityRadioField, setVisibilityRadioField] = useState('Public')
  const [courseDescriptionEditorState, setCourseDescriptionEditorState] = useState(EditorState.createEmpty())
  const [assignmentDescriptionEditorState, setAssignmentDescriptionState] = useState(EditorState.createEmpty())
  const [assignmentRequired, setAssignmentRequired] = useState(false)

  const {
    data: {
      id: userId,
      partner_profile: {
        id: partnerProfileId,
        greenhouse_api_key: greenhouseApiKey,
        greenhouse_api_user_id: greenhouseApiUserId
      } = {}
    } = {}
  } = useGetCurrentUserQuery()

  const { data: greenhouseApiJobs, error, isLoading } = useFetchGreenhouseJobsQuery(partnerProfileId, {
    skip: !greenhouseApiUserId || !greenhouseApiKey
  })

  const courseData = useSelector(state => state.courseBuilder || {})
  const courseSlug = courseData.slug

  if (error || isLoading) return

  const { courseStepsContent } = getCourseStepsContent(episodeCount)

  const courseBuilderValues = {
    courseSlug,
    expertCount,
    setExpertCount,
    quizCount,
    setQuizCount,
    courseMaterialCount,
    setCourseMaterialCount,
    assignmentMaterialCount,
    setAssignmentMaterialCount,
    courseNameList,
    setCourseNameList,
    episodeCount,
    setEpisodeCount,
    visibilityRadioField,
    setVisibilityRadioField,
    courseDescriptionEditorState,
    setCourseDescriptionEditorState,
    assignmentDescriptionEditorState,
    setAssignmentDescriptionState,
    assignmentRequired,
    setAssignmentRequired,
    userId,
    partnerProfileId,
    greenhouseApiKey,
    greenhouseApiUserId,
    greenhouseApiJobs,
  }

  return (
    <BuilderProvider
      initialStepId="courseNameThumbnail"
      stepsContent={courseStepsContent}
      sendData={sendCourseData}
      formHook={useBuildMultiForm}
      validateAndContinue={validateAndContinue}
      builderStateSelector="courseBuilder"
      disabledSteps={courseData?.disabledCourseSteps}
      builderSpecificValues={courseBuilderValues}
      createNew={createNewCourse}
      fileFormDataPrefix="course"
    >
      {children}
    </BuilderProvider>
  )
}

export const useCourseBuilderContext = useBuilderContext
