import { useTranslation } from 'i18n/TranslationContext'

export const useSwitchConfigs = () => {
  const { t } = useTranslation()
  return (
    [
      { id: 'course-completed', name: 'courseCompleted', label: t('Course Completed') },
      { id: 'assignment-completed', name: 'assignmentSubmitted', label: t('Assignment Completed') },
      { id: 'resume-uploaded', name: 'resumeUploaded', label: t('Resume Uploaded') },
      { id: 'has-link', name: 'hasPortfolioLink', label: t('Portfolio Added') },
    ]
  )
}
