import { Box } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { FormHeader } from 'components'

export const FormTemplate = ({ nextStep, formTitle, formForStep, bannerComponent, introTextComponent=null, buttonDisabled=false, disabledNavTooltipText, children }) => {
  const { t } = useTranslation()
  const Banner = bannerComponent
  const IntroText = introTextComponent
  return (
    <Box className="form-template" display="flex" flexDirection="column" height="100%" justifyContent="space-between">
      <Box>
        <FormHeader headerDisplay textValue={formTitle} nextStep={nextStep} t={t} buttonDisabled={buttonDisabled} disabledNavTooltipText={disabledNavTooltipText} />
        {Banner && <Banner />}
        <Box p={formTitle === 'Review & Submit' ? 25 : 50}>
          {IntroText && <IntroText />}
          <FormProvider {...formForStep}>
            {children}
          </FormProvider>
        </Box>
      </Box>
      <FormHeader textValue={t('Step: {{formTitle}}', { formTitle })} nextStep={nextStep} t={t} buttonDisabled={buttonDisabled} disabledNavTooltipText={disabledNavTooltipText} />
    </Box>
  )
}
