import React from 'react'
import { TableItem } from 'shared/TableItem'
import { Tooltip, Box } from '@chakra-ui/react'
import Column1 from './Column1'
import Column2 from './Column2'

const OpportunityItem = ({ partnerView, displayName, buttonsComponent, column2Value, ...props }) => {
  const isClosed = props.status === 'closed'

  const renderContent = (children) => {
    if (isClosed) {
      return (
        <Box position="relative">
          <Tooltip label="This opportunity is no longer available">
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={1}
              cursor="not-allowed"
            />
          </Tooltip>
          <Box opacity={0.5}>
            {children}
          </Box>
        </Box>
      )
    }
    return children
  }

  return (
    <TableItem
      padding={['16px 24px', null, '16px']}
      borderBottom={['1px solid var(--chakra-colors-gray-100)', null, 'unset']}
      paddingColumn2="0px"
      columns={[
        renderContent(
          <Column1
            key={1}
            partnerView={partnerView}
            name={displayName ? displayName : props.name}
            companyName={props.company_name}
            poster={props.poster}
            id={props.id}
          />
        ),
        renderContent(<Column2 key={2} column2Value={column2Value} />),
        renderContent(<>{buttonsComponent}</>)
      ]}
    />
  )
}

export default OpportunityItem
