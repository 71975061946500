import { Box } from '@chakra-ui/react'
import { SubTitleLabel } from './shared'

export const CourseSubtitle = ({ authors, visibility }) => {
  return (
    <Box paddingTop={['14px', null, '16px']}>
      <Box
        display="inline-block"
        opacity="0.8"
        fontFamily="Poppins"
        fontSize="18px"
        fontWeight="500"
      >
        <SubTitleLabel authors={authors} visibility={visibility} />
      </Box>
    </Box>
  )
}
