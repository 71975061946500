import { Box, FormControl, FormLabel, HStack, Switch, Text } from '@chakra-ui/react'
import { useSwitchConfigs } from 'hooks/partnerUx/useSwitchConfigs'

const FormSwitch = ({ id, label, onChange, checkedValue, name }) => (
  <FormControl id={id}>
    <FormLabel>
      <HStack spacing={4} cursor="pointer">
        <Switch onChange={onChange} isChecked={checkedValue} name={name} colorScheme="zettaBlue" />
        <Text fontFamily="Poppins">{label}</Text>
      </HStack>
    </FormLabel>
  </FormControl>
)

export const Switches = ({ onChange, formValues }) => {
  const switchConfigs = useSwitchConfigs()
  return (
    <Box className="switches">
      {switchConfigs.map(({ id, name, label }) => (
        <FormSwitch
          key={id}
          id={id}
          name={name}
          label={label}
          checkedValue={formValues[name]}
          onChange={onChange}
        />
      ))}
    </Box>
  )
}
