import React from 'react'
import { Tr, Flex } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'
import { TableItemMap } from 'shared'
import { WelcomeBar } from '../../WelcomeBar'
import { PageOuterContainer, ListHeader, ListBlock, OpportunitiesTable, PartnerOpportunity, PrimaryButton } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { usePartnerOpportunities } from 'hooks/opportunities/usePartnerOpportunities'

const ActiveOpportunities = () => {
  const { t } = useTranslation()
  const { data, isLoading, error } = usePartnerOpportunities()

  if (isLoading) {
    return (
      <AppLoadingState />
    )
  }

  if (error || !data) {
    return (
      <AppLoadingState />
    )
  }

  const activeOpportunities = data.active || []

  const buildNewOpportunityButton = (
    <PrimaryButton
      textValue={t('Build New Opportunity')}
      linkValue="/opportunities/new"
    />
  )

  return (
    <ListBlock>
      <Flex justifyContent="space-between" alignItems="center" mb={4} pb={8}>
        <ListHeader as="h1" mb={0} paddingBottom={0} >{t('Active Opportunities')}</ListHeader>
        {buildNewOpportunityButton}
      </Flex>
      <OpportunitiesTable headings={[t('opportunity'), t('applications'), t('actions')]}>
        <TableItemMap
          collection={activeOpportunities}
          listQuery={{ data, isLoading: false, error: null }}
          emptyStateCopy={t('Currently, there are no active opportunities.')}
        >
          {(props) => (
            <Tr key={props.id}>
              <PartnerOpportunity {...props} type="active" />
            </Tr>
          )}
        </TableItemMap>
      </OpportunitiesTable>
    </ListBlock>
  )
}

const InactiveOpportunities = () => {
  const { t } = useTranslation()
  const { data, isLoading, error } = usePartnerOpportunities()

  if (isLoading) {
    return (
      <AppLoadingState />
    )
  }

  if (error || !data) {
    return (
      <AppLoadingState />
    )
  }

  const inactiveOpportunities = data.inactive || []

  return (
    <ListBlock>
      <ListHeader as="h1">{t('Inactive Opportunities')}</ListHeader>
      <OpportunitiesTable headings={[t('opportunity'), t('applications'), t('actions')]}>
        <TableItemMap
          collection={inactiveOpportunities}
          listQuery={{ data, isLoading: false, error: null }}
          emptyStateCopy={t('Currently, there are no inactive opportunities.')}
        >
          {(props) => (
            <Tr key={props.id}>
              <PartnerOpportunity {...props} type="inactive" />
            </Tr>
          )}
        </TableItemMap>
      </OpportunitiesTable>
    </ListBlock>
  )
}

export const PartnerPageContent = () => {
  return (
    <PageOuterContainer greenBg="true">
      <WelcomeBar />
      <ActiveOpportunities />
      <InactiveOpportunities />
    </PageOuterContainer>
  )
}
