import { Button } from '@chakra-ui/react'
import { useTalentProfileCsvExport } from 'Partner/TalentListPage/useTalentProfileListData'
import { useTranslation } from 'i18n/TranslationContext'

export const ExportCsvButton = ({ id, ...props }) => {
  const { t } = useTranslation()
  const { handleExportCsv, isLoading } = useTalentProfileCsvExport()

  return (
    <Button
      size="lg"
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      onClick={handleExportCsv}
      isLoading={isLoading}
      {...props}
    >
      {t('Export to CSV')}
    </Button>
  )
}

