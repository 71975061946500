import { useTranslation } from 'i18n/TranslationContext'
import { PageOuterContainer, PageTitle } from 'shared/TalentsLayout'
import { Flex } from '@chakra-ui/react'
import { ProfileEditForm } from './ProfileEditForm'
import { usePageTitle } from '../../usePageTitle'

export const EditProfile = () => {
  const { t } = useTranslation()
  usePageTitle(t('Edit your profile'))

  return (
    <PageOuterContainer>
      <Flex
        justifyContent={['center', null, 'space-between']}
        paddingBottom={['44px', null, '50px']}
      >
        <PageTitle as="h1">
          {t('Edit your profile')}
        </PageTitle>
      </Flex>
      <ProfileEditForm />
    </PageOuterContainer>
  )
}
