export const formStyles = () => {
  const sharedStyles = {
    errorBorderColor: '#CD0050',
    _hover: { borderColor: '#0F204B' },
    _focusVisible: { borderColor: '#3182ce', boxShadow: '0 0 0 1px #3182ce' },
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: ['14px', null, '18px'],
    borderRadius: '4px',
    border: '2px solid #0F204B',
  }

  const textFieldStyles = {
    p: '20px',
    height: ['175px', null, '250px'],
    size: 'xl',
    fontFamily: 'Poppins',
  }

  const disabledStyleProp = {
    border: '2px solid #D3D3D3'
  }

  const formControlStyles = {
    display: 'flex',
    flexDirection: 'column'
  }

  const inputFieldStyles = {
    height: ['54px', null, '74px'],
    size: 'lg',
    fontFamily: 'Poppins',
  }

  const baseLabelStyles = {
    fontWeight: '500',
    fontSize: ['20px', null, '24px'],
  }

  const radioFieldStyles = {
    ...baseLabelStyles,
    fontFamily: 'Poppins',
  }

  const formLabelStyles = {
    ...baseLabelStyles,
    fontFamily: 'Spline Sans Mono',
  }

  const tagStyle = {
    background: '#b1f4ae',
    color: '#000',
    margin: '10px 5px',
    fontFamily: 'Poppins',
  }

  const formHeaderStyle = {
    pl: '16px',
    fontFamily: 'Spline Sans Mono',
    width: '50%',
    float: 'left',
    textTransform: 'uppercase',
  }

  const formPrimaryButtonStyle = {
    fontSize: '18px',
    fontWeight: '500',
    padding: '0 12px',
    float: 'right',
    mt: '5px',
    colorScheme: 'zettaWhiteOutline',
  }

  const bannerGray = {
    backgroundColor: '#D9D9D9',
  }

  const checkboxFieldStyles = {
    width: '20%',
    padding: '15px',
    fontFamily: 'Poppins',
  }

  const selectFieldStyles = {
    ...inputFieldStyles,
    appearance: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 12px center',
  }

  return {
    sharedStyles,
    textFieldStyles,
    inputFieldStyles,
    radioFieldStyles,
    formLabelStyles,
    disabledStyleProp,
    tagStyle,
    formHeaderStyle,
    formPrimaryButtonStyle,
    formControlStyles,
    checkboxFieldStyles,
    selectFieldStyles,
    bannerGray,
  }
}
