import { useTranslation } from 'i18n/TranslationContext'
import { FormProvider, useFormContext } from 'react-hook-form'
import { Box, Button, Center, Flex, useToast } from '@chakra-ui/react'
import { FormContent } from './FormContent'
import { useUpdateTalentProfileMutation } from 'features/talentProfilesApi'
import { sessionsApi } from 'features/sessionsApi'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useProfileForm } from '../useProfileForm'
import { LeaveFormControl } from 'LeaveFormControl'
import { usePageLeaveConfirmation } from 'shared/usePageLeaveConfirmation'

const CancelButton = () => {
  const { t } = useTranslation()
  return (
    <Button
      as={LeaveFormControl}
      to="/courses"
      fontWeight="500"
      colorScheme="zettaWhite"
    >
      {t('Cancel')}
    </Button>
  )
}

const SubmitButton = () => {
  const { t } = useTranslation()
  const { formState: { isSubmitting } } = useFormContext()

  return (
    <Button
      fontWeight="500"
      colorScheme="zettaBlue"
      isLoading={isSubmitting}
      type="submit"
    >
      {t('Save changes')}
    </Button>
  )
}

const FormControls = () => (
  <Flex
    flexDirection={['column', null, 'row']}
    padding={[
      '24px 0',
      null,
      '32px 0'
    ]}
    marginBottom={[null, null, '1px']}
    bgColor="white"
  >
    <SubmitButton width={['100%', null, 'unset']} />
    <Box
      minWidth={[null, null, '8px']}
      minHeight={['8px', null, 'unset']}
    />
    <CancelButton width={['100%', null, 'unset']} />
  </Flex>
)

const FormLayout = () => {
  usePageLeaveConfirmation()

  return (
    <Box
      marginBottom="54px"
      bgColor="white"
    >
      <Center>
        <Center flexDirection="column" width="100%">
          <Box
            fontFamily="Poppins"
            padding={[
              '0 16px 0 16px',
              null,
              '48px 48px 185px 48px'
            ]}
            width="100%"
          >
            <FormContent />
            <FormControls />
          </Box>
        </Center>
      </Center>
    </Box>
  )
}

export const ProfileEditForm = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [updateTalentProfile, { isSuccess }] = useUpdateTalentProfileMutation()
  const methods = useProfileForm()
  const { handleSubmit, reset } = methods
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSuccess) { return }

    reset({}, { keepValues: true })
    toast({
      title: t('Changes were successfully saved'),
      status: 'success',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [isSuccess, toast, reset, t])

  const onSubmit = async data => {
    updateTalentProfile(data)
      .then((data) => {
        if (window.location.pathname.includes('partner')) {
          dispatch(sessionsApi.util.invalidateTags(['CurrentUser']))
        }
      })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout />
      </form>
    </FormProvider>
  )
}
