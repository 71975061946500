import { useRef } from 'react'
import useSendFormData from 'hooks/opportunityBuilder/useSendFormData'
import { useSelector } from 'react-redux'

export const ReviewAndSubmit = ({ nextStep, formForStep }) => {

  const opportunityData = useSelector(state => state.opportunityBuilder || {})
  const initialized = useRef(false)

  // When the form changes, handle the data being sent to the backend
  useSendFormData(opportunityData, initialized)

  return (
    'ReviewAndSubmit'
  )
}
