import React, { useState, useMemo } from 'react'
import { useGetCourseOptions } from 'hooks/opportunityBuilder/useFormHelper'
import SearchableSelect from 'components/forms/fields/SearchableSelect'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'

const CourseSelectorField = ({ formDisabled, errorVal }) => {
  const [inputValue, setInputValue] = useState('')
  const allCourseOptions = useGetCourseOptions()
  const { control } = useFormContext()
  const { t } = useTranslation()
  const [ fieldMessage, setFieldMessage ] = useState('')

  const filteredCourseOptions = useMemo(() => {
    let options = allCourseOptions
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    if (inputValue) {
      options = options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    }

    return options.slice(0, 3)
  }, [allCourseOptions, inputValue])

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const handleSelectChange = (value) => {
    const selectedCourse = allCourseOptions.find(option => option.value === value)

    if (selectedCourse) {
      if (selectedCourse.status === 'draft') {
        setFieldMessage(t('The selected course is in a Draft state. You\'ll need to submit the course for review before opening this opportunity.'))
      } else if (selectedCourse.status === 'pending') {
        setFieldMessage(t('The selected course is in a Pending state. Once approved by the Making Space team, your opportunity will automatically be opened.'))
      } else {
        setFieldMessage(null)
      }
    }
  }

  return (
    <SearchableSelect
      name="course_id"
      control={control}
      options={filteredCourseOptions}
      label={t('Associated Course')}
      placeholder={t('Select one.')}
      helperText={t('Associate your opportunity with a course on Making Space. Candidates will be required to complete the course before they can apply.')}
      isDisabled={formDisabled}
      fieldRequired={true}
      errorVal={errorVal}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      infoMessage={fieldMessage}
    />
  )
}

export default CourseSelectorField