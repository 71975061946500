import {
  addFormError,
  removeFormError,
  addFormField,
  addMultipleFormFields,
  removeValueFromArray,
} from 'features/opportunities/opportunityBuilderSlice' // Import the action creator

const resetFormStepsArrays = async(stepId, dispatch) => {
  // remove the step from both success and failed step arrays since it's being revalidated
  dispatch(removeValueFromArray({ key: 'invalid_builder_steps', value: stepId }))
  dispatch(removeValueFromArray({ key: 'valid_builder_steps', value: stepId }))
}

const extractSkills = (skills, formToValidate, stepId, dispatch) => {
  // set form value skills from the state before validation
  if(stepId === 'opportunityDescription') {
    formToValidate.setValue('skills', skills.join(',') || [])
    dispatch(addFormField({ key: 'skills', value: '' }))
  }
}

const validateProperty = (property, formData, formToValidate, dispatch) => {
  return formToValidate.trigger(property).then(isValid => {
    if (isValid) {
      handleValidProperty(property, formData, dispatch)
      dispatch(removeFormError({ key: property }))
      return true
    } else {
      handleInvalidProperty(property, formData, formToValidate, dispatch)
      return property === 'name'
    }
  }).catch(error => {
    console.error('Error occurred during form validation:', error)
    return false
  })
}

const hasFilledData = (value) => {
  if (!value) return false
  if (typeof value === 'object') {
    return Object.keys(value).length > 0
  } else if (typeof value === 'string') {
    return value.trim().length > 0
  } else if (typeof value === 'number') {
    return value > 0
  }
  return false
}

const handleValidProperty = (property, formData, dispatch) => {
  const isFilledData = hasFilledData(formData[property])
  if ((isFilledData) && !property.includes('-file')) {
    handleRegularField(property, formData, dispatch)
  }
}

const handleInvalidProperty = (property, formData, formToValidate, dispatch) => {
  const formError = formToValidate.formState.errors[property]
  const errorMessage = formError?.message || ''
  dispatch(addFormError({ [property]: errorMessage }))
  !property.includes('-file') && dispatch(addFormField({ key: property, value: formData[property] || null }))
}

const handleRegularField = (property, formData, dispatch) => {
  dispatch(addFormField({ key: property, value: formData[property] }))
}

const validateAndContinue = async(dispatch, builderForms, stepId, skills) => {
  if (stepId === 'reviewSubmit') return true

  try {
    const formToValidate = builderForms[`${stepId}`]

    resetFormStepsArrays(stepId, dispatch)
    extractSkills(skills, formToValidate, stepId, dispatch)

    const formData = formToValidate.getValues()
    const validationPromises = Object.keys(formData).map(property =>
      validateProperty(property, formData, formToValidate, dispatch)
    )

    const validationResults = await Promise.all(validationPromises)
    const isValidForm = validationResults.every(result => result === true)
    const nameMissing = validationResults.every(result => result !== 'nameMissing')

    if(isValidForm) {
      dispatch(addMultipleFormFields({ key: 'valid_builder_steps', value: stepId }))
    } else {
      dispatch(addMultipleFormFields({ key: 'invalid_builder_steps', value: stepId }))
    }

    return nameMissing
  } catch (error) {
    console.error('Error occurred during form validation:', error)
  }
}

export default validateAndContinue
