import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, ModalCloseButton } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'

const UnsavedChangesModal = ({ isOpen, onConfirm, onCancel, message }) => {
  const { t } = useTranslation()

  const headerStyles = {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '700',
  }

  const textStyles = {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '400'
  }

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="3xl" isCentered closeOnOverlayClick={false} className="confirm-exit-modal">
      <ModalOverlay />
      <ModalContent padding="40px">
        <ModalCloseButton />
        <ModalHeader {...headerStyles}>{t('Are you sure?')}</ModalHeader>
        <ModalBody>
          <Text {...textStyles}>{message}</Text>
        </ModalBody>
        <ModalFooter pt={6}>
          <Button size="lg" fontSize="18px" variant="outline" colorScheme="zettaBlue" onClick={onCancel} mr={5}>
            {t('No, take me back')}
          </Button>
          <Button size="lg" fontSize="18px" colorScheme="zettaBlue" onClick={onConfirm}>
            {t('Yes, I\'m sure')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UnsavedChangesModal
