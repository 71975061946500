import { useSelector } from 'react-redux'
import { useListCourseForPartnerQuery } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

export const useGetOpportunityStatusOptions = (t) => {
  const opportunityData = useSelector(state => state.opportunityBuilder || {})

  const baseOptions = [
    { value: 'draft', label: t('Draft') },
    { value: 'open', label: t('Open') },
  ]

  if (opportunityData.date_opened) {
    baseOptions.push(
      { value: 'paused', label: t('Paused') },
      { value: 'closed', label: t('Closed') }
    )
  }

  return baseOptions
}

export const useGetCourseOptions = () => {
  const { data: userData } = useGetCurrentUserQuery()
  const partnerId = userData?.partner_profile?.id

  const { data: courses, isLoading: coursesLoading } = useListCourseForPartnerQuery(partnerId, {
    skip: !partnerId
  })

  if (coursesLoading) {
    return []
  }

  return courses?.map(course => ({
    value: course.id,
    label: course.name,
    status: course.status,
    createdAt: course.created_at,
  })) || []
}
