import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { GridItem } from '@chakra-ui/react'
import BuilderLayout from 'components/builder/BuilderLayout'
import OpportunityStepsAccordion from './OpportunityStepsAccordion'
import { FormRender } from './FormRender'
import { OpportunityBuilderProvider } from './OpportunityBuilderContext'
// import useNavBlocker from 'hooks/useNavBlocker'
// import { IGNORE_NAV_BLOCKER } from 'constants'

// const ConfirmExitBlockerModal = () => {
//   const { t } = useTranslation()
//   const currentFormDirty = true
//   const { ConfirmExitModalComponent } = useNavBlocker(
//     t('You may have unsaved changes. Would you still like to proceed without saving?'),
//     currentFormDirty
//   )

//   return <ConfirmExitModalComponent />
// }

const getPageTitle = (t) => {
  return window.location.pathname.includes('/new') ?
    t('Build Opportunity') :
    t('Edit Opportunity')
}

export const OpportunityBuilder = () => {
  const { t } = useTranslation()
  const pageTitle = getPageTitle(t)
  usePageTitle(pageTitle)

  const handleSaveExit = async () => {
    // Implementation for saving and exiting
  }

  return (
    <OpportunityBuilderProvider>
      <BuilderLayout
        pageTitle={pageTitle}
        description={`
          ${t('Showcase a career opportunity for top talent to review, express interest, and apply for.')} 
          ${t('Highlight the key role details to attract potential candidates.')}
        `}
        backLinkText={t('Back to Opportunities')}
        backLinkHref="/opportunities"
        onSave={handleSaveExit}
      >
        {/* <ConfirmExitBlockerModal /> */}
        <OpportunityStepsAccordion />
        <GridItem colSpan={4}>
          <FormRender />
        </GridItem>
      </BuilderLayout>
    </OpportunityBuilderProvider>
  )
}
