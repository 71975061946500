import { createSlice } from '@reduxjs/toolkit'
import { commonFormReducers, createResetReducer } from '../common/formReducers'

const initialState = {
  id: '',
  name: '',
  status: '',
  date_opened: null,
  disabledSteps: [],
  errors: {},
  'image-fileFilename': '',
  location: {},
  location_id: '',
  workplace_model: '',
  course_id: '',
  employment_type: '',
  salary_min: '',
  salary_max: '',
  salary_frequency: '',
  close_date: '',
  description: '',
  skills: '',
  valid_builder_steps: [],
  invalid_builder_steps: [],
}

const opportunityBuilderSlice = createSlice({
  name: 'opportunityBuilder',
  initialState,
  reducers: {
    ...commonFormReducers,
    resetOpportunityBuilder: createResetReducer(initialState),
  },
})

export const {
  addFormField,
  removeMultipleFormFields,
  addMultipleFormFields,
  removeValueFromArray,
  addFormError,
  removeFormError,
  resetOpportunityBuilder
} = opportunityBuilderSlice.actions

export const opportunityBuilderSliceReducer = opportunityBuilderSlice.reducer
