import './App.css'
import { useTranslation, TranslationProvider } from './i18n/TranslationContext'
import { Box, ChakraProvider } from '@chakra-ui/react'

import { theme } from './theme'

import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom'
import React, { useEffect, useLayoutEffect } from 'react'

import { UnauthenticatedApp } from './UnauthenticatedApp'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { AuthenticatedApp } from './AuthenticatedApp'
import { AppLoadingState } from './AppLoadingState'
import { useSelector } from 'react-redux'
import { pageTitleSelector } from 'features/page/selectors'
import { ContactRequest } from './Talent/ContactRequest'
import { GlobalFooter } from 'GlobalFooter'
import useRedirection from './hooks/useRedirection'

const useScrollToTop = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}

const AppWithAuthentication = () => {
  const { isFetching, isError } = useGetCurrentUserQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const { handleAuthenticatedRedirection } = useRedirection()

  useEffect(() => {
    handleAuthenticatedRedirection(isFetching, isError, location, navigate)
  }, [isFetching, isError, navigate, location, handleAuthenticatedRedirection])

  if (isFetching) { return <AppLoadingState /> }
  if (isError) { return <UnauthenticatedApp /> }

  return <AuthenticatedApp />
}

const AppRouter = () => (
  <Routes>
    <Route
      path="*"
      element={<AppWithAuthentication />}
    />
    <Route
      path="/talent/contact_requests/:requestId"
      element={<ContactRequest />}
    />
  </Routes>
)

const useDocumentTitle = () => {
  const pageTitle = useSelector(pageTitleSelector)

  useEffect(() => {
    if (pageTitle) {
      document.title = `${pageTitle} - Making Space`
    } else {
      document.title = ''
    }
  }, [pageTitle])
}

const NavigationAnnouncement = () => {
  const { t } = useTranslation()
  const pageTitle = useSelector(pageTitleSelector)

  if (!pageTitle) { return null }

  return (
    <Box aria-live="polite" position="absolute" zIndex="-100">
      {t('Navigated to "{{pageTitle}}" page', { pageTitle })}
    </Box>
  )
}

const useToastScroll = () => {
  useEffect(() => {
    const element = document.getElementById('chakra-toast-manager-top')

    if (!element) {
      return
    }

    const positionUpdate = () => {
      const y = window.scrollY

      const element = document.getElementById('chakra-toast-manager-top')

      if (!element) { return }

      if (y < 100) {
        Object.assign(
          element.style,
          {
            position: 'absolute',
            top: '100px'
          }
        )
      } else {
        Object.assign(
          element.style,
          {
            position: 'fixed',
            top: '0'
          }
        )
      }
    }

    positionUpdate()

    window.addEventListener('scroll', positionUpdate)

    return () => {
      window.removeEventListener('scroll', positionUpdate)
    }
  })
}

export const App = () => {
  const isCoursePage = !!useMatch('/courses/:id')
  useDocumentTitle()
  useScrollToTop()
  useToastScroll()
  const location = useLocation()
  const { handleUnauthenticatedRedirection } = useRedirection()

  useEffect(() => {
    handleUnauthenticatedRedirection(location)
  }, [location, handleUnauthenticatedRedirection])

  return (
    <TranslationProvider>
      <ChakraProvider theme={theme}>
        <NavigationAnnouncement />
        <AppRouter />
        {
          !isCoursePage && (
            <GlobalFooter />
          )
        }
      </ChakraProvider>
    </TranslationProvider>
  )
}
