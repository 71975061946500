import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex, Td } from '@chakra-ui/react'
import { DetailedProfile } from './shared'
import { SubTitleLabel } from '../../../CourseList/CourseItems/shared'
import { Link } from 'react-router-dom'
import { DraftButton } from 'components'

const CourseCardMobile = ({
  id,
  name,
  status,
  poster,
  authors,
  visibility
}) => {
  const deterimineLink = status === 'draft' ? `/courses/${id}/edit` : `/courses/dashboard/${id}`
  return (
    <Link to={deterimineLink}>
      <Flex paddingBottom="12px">
        <Center flexShrink="0" marginRight="8px" flexBasis="146px">
          <img
            style={{
              height: '96px',
              objectFit: 'contain'
            }}
            src={poster}
            alt=""
          />
        </Center>
        <Flex
          whiteSpace="normal"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Box
            fontSize="20px"
            fontFamily="Spline Sans Mono"
            fontWeight="bold"
          >
            {name}
          </Box>
          <Box
            fontFamily="Poppins"
            fontSize="14px"
            fontWeight="500"
            color="#070F22"
            opacity="0.8"
          >
            <SubTitleLabel
              authors={authors}
              visibility={visibility}
            />
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}

export const CourseMobile = ({
  id,
  ...restProps
}) => {
  const { t } = useTranslation()
  return (
    <Td
      border="0"
      padding={[
        '12px 0',
        null,
        '24px 10px'
      ]}
    >
      <CourseCardMobile
        id={id}
        {...restProps}
      />
      {restProps.status === 'draft' ? (
        <DraftButton id={id} width="100%">
          {t('Edit Course')}
        </DraftButton>
      ) : (
        <DetailedProfile id={id} width="100%">
          {t('Detailed profile')}
        </DetailedProfile>
      )}
    </Td>
  )
}
