import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
} from '@chakra-ui/react'

const PrimaryButton = ({ linkValue, textValue, ...props }) => {
  return (
    <Button
      as={Link}
      to={linkValue}
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      padding="0 12px"
      {...props}
    >
      {textValue}
    </Button>
  )
}

export default PrimaryButton
